import React, { useState, useEffect } from 'react';
import './App.css';

function App() {
  const [submitted, setSubmitted] = useState(false);
  const [name, setName] = useState('');
  const [pin, setPin] = useState('');
  const [password, setPassword] = useState('');
  const [countdown, setCountdown] = useState(calculateCountdown());
  const [isAdmin, setIsAdmin] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  // Lista de usuarios con sus pines y contraseñas
  const users = [
    { name: 'Allegra', pin: '3945', password: 'FIDELIO' },
    { name: 'Alejandro', pin: '8080', password: 'FURIOSO' },
    { name: 'Alexandra', pin: '9191', password: 'FIDELIO' },
    { name: 'Alexia', pin: '1100', password: 'TROMBONE' },
    { name: 'Andrea', pin: '2394', password: 'TARIFA' },
    { name: 'Andres', pin: '7676', password: 'CANSERBERO' },
    { name: 'Anna', pin: '6445', password: 'GIORNATA' },
    { name: 'Bernat', pin: '7770', password: 'BONSAI' },
    { name: 'Bruno', pin: '9445', password: 'PRELUDIO' },
    { name: 'Clara', pin: '5436', password: 'TOMALALATA' },
    { name: 'Claudia', pin: '3542', password: 'MADAM CURIE' },
    { name: 'Clement', pin: '9238', password: 'ALLEGRO' },
    { name: 'Daniela', pin: '6589', password: 'MINION' },
    { name: 'Edu', pin: '3853', password: 'CAZZO' },
    { name: 'Emelin', pin: '8892', password: 'FIDELIO' },
    { name: 'Ferry', pin: '9678', password: 'BONSAI' },
    { name: 'Guillermo', pin: '2938', password: 'FIDELIO' },
    { name: 'Heidi', pin: '8872', password: 'BONSAI' },
    { name: 'Ines', pin: '8092', password: 'MARCATO' },
    { name: 'Izan', pin: '8309', password: 'BONSAI' },
    { name: 'Jana', pin: '5531', password: 'PUTICARTE' },
    { name: 'Xavi', pin: '0000', password: 'NAIROBI' },
    { name: 'Jorge', pin: '9382', password: 'FIDELIO' },
    { name: 'Kevin', pin: '8390', password: 'MASVERD' },
    { name: 'Lluis', pin: '9921', password: 'FIDELIO' },
    { name: 'Luca', pin: '9201', password: 'ROÑA' },
    { name: 'Lucas', pin: '6784', password: 'FIDELIO' },
    { name: 'Manel', pin: '9205', password: 'KOJIMA' },
    { name: 'Nicolas', pin: '8877', password: 'FIDELIO' },
    { name: 'Nuran', pin: '2841', password: 'FALASTIN' },
    { name: 'Oscar', pin: '8931', password: 'ROÑA' },
    { name: 'Pau', pin: '8901', password: 'FIDELIO' },
    { name: 'Pol', pin: '8937', password: 'BONSAI' },
    { name: 'Sebas', pin: '5544', password: 'ASTOCATO' },
    { name: 'Sergio', pin: '2201', password: 'NAMASTE' },
    { name: 'Aitana', pin: '8490', password: 'FIDELIO' },
    { name: 'Tomas', pin: '7593', password: 'ROÑA' },
    { name: 'Tristan', pin: '9901', password: 'MCLOVIN' },
    { name: 'Ursula', pin: '8475', password: 'SONATA' },
    { name: 'Victor', pin: '8350', password: 'FIDELIO' },
    { name: 'Werner', pin: '6436', password: 'BONSAI' },
    { name: 'Marco', pin: '7903', password: 'GATSBY' },
    { name: 'Rafaella', pin: '8939', password: 'TOUS' },
    { name: 'Nuria', pin: '1208', password: 'AFRODITA' },
    { name: 'Irakli', pin: '8390', password: 'KROKODIL' },
    { name: 'Laura', pin: '8340', password: 'FIDELIO' },
    { name: 'Carmen', pin: '0909', password: 'MASVERD'},
    { name: 'Miriam', pin: '0909', password: 'FIDELIO'},
    { name: 'Luis', pin: '0909', password: 'FIDELIO'},
    { name: 'Max', pin: '0909', password: 'FIDELIO'},
    { name: 'Selina', pin: '0909', password: 'FIDELIO'},
    { name: 'Ivan', pin: '0909', password: 'TERUEL'},
    { name: 'Carlos', pin: '0909', password: 'TERUEL'},
    { name: 'Paulina', pin: '0808', password: 'AMNESIA'},
    { name: 'Santi', pin: '7373', password: 'GROWTH'},
    { name: 'Nahuel', pin: '8989', password: 'ZURICH'},
    { name: 'Mireia', pin: '8989', password: 'FIDELIO'},
    { name: 'Carlota', pin: '9090', password: 'SONATA'},
    { name: 'Nadia', pin: '8790', password: 'GHIBLI'},
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown(calculateCountdown());
    }, 1000); // Actualiza cada segundo
    return () => clearInterval(timer);
  }, []);

  function calculateCountdown() {
    const currentDate = new Date();
    const targetDate = new Date('2024-06-14'); // Fecha objetivo (14 de Junio)
    const difference = targetDate - currentDate;
    if (difference > 0) {
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);
      return { days, hours, minutes, seconds };
    } else {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (name.toLowerCase() === 'admin' && pin === '1976') {
      setIsAdmin(true);
      return;
    }
    const user = users.find(u => u.name.toLowerCase() === name.toLowerCase() && u.pin === pin);
    if (user) {
      setPassword(user.password);
      setSubmitted(true);
    }
  };

  const handlePinChange = (e) => {
    const value = e.target.value;
    if (!isNaN(value) && value.length <= 4) {
      setPin(value);
    }
  };

  const handleUserClick = (user) => {
    setSelectedUser(user);
  };

  return (
    <div className="container">
      <h1 className="title">AT Roaring 20s</h1>
      {!isAdmin && !submitted && (
        <form onSubmit={handleSubmit} className="form">
          <label htmlFor="name" className="form-label">PRIMER Nombre:</label><br />
          <input
            type="text"
            id="name"
            name="name"
            placeholder="Ingresa tu nombre"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="input"
          /><br />
          <label htmlFor="pin" className="form-label">PIN (4 dígitos):</label><br />
          <input
            type="password"
            id="pin"
            name="pin"
            placeholder="Ingresa tu PIN de 4 dígitos"
            value={pin}
            onChange={handlePinChange}
            className="pin-input"
          /><br />
          <button type="submit" className="button">Enviar</button>
        </form>
      )}
      {isAdmin && (
        <div className="admin-panel">
          <h2>Panel de Control</h2>
          <div className="user-list">
            {users.map((user, index) => (
              <div
                key={index}
                className="user"
                onClick={() => handleUserClick(user)}
              >
                {user.name}
              </div>
            ))}
          </div>
          {selectedUser && (
            <div className="user-details">
              <h3>{selectedUser.name}</h3>
              <p><strong>PIN:</strong> {selectedUser.pin}</p>
              <p><strong>Contraseña:</strong> {selectedUser.password}</p>
            </div>
          )}
        </div>
      )}
      {submitted && !isAdmin && (
        <div className="animation-container">
          <p className="password-text">Tu contraseña es</p>
          <p className="password">{password}</p>
          <div className="countdown-container">
            <p className="countdown-text">Días Restantes:</p>
            <p className="countdown">
              {countdown.days}D {countdown.hours}H {countdown.minutes}M {countdown.seconds}S
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
